.inputForm {
  position: relative;
  padding: 8px;
}
.label {
  position: absolute;
  top: -5px;
  left: 47px;
  padding-left: 4px;
  padding-right: 4px;
  background: #f6f8fa;
}

.Input {
  padding: 12px;
  min-height: 50px;
  max-height: 60px;
}
