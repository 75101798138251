.inputForm {
  position: relative;
  padding: 8px;
}
.label {
  position: absolute;
  left: 47px;
  padding-left: 4px;
  padding-right: 4px;
  background: #f6f8fa;
}

.Input {
  padding: 10px;
  background-color: #f6f8fa;
  padding-left: 47px;
  margin-bottom: 5px;
}
